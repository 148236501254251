import React from 'react';

const Footer = () => {
  return (
    <div className="bg-gray-900 text-white">
      <div className="container mx-auto py-5">
        
      {/* <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-6">
          <div className="space-y-4">
            <h5 className="font-semibold">Who we are</h5>
          </div>

          <div className="space-y-4">
            <h5 className="font-semibold">Auto Insurance</h5>
          </div>

          <div className="space-y-4">
            <h5 className="font-semibold">Company</h5>
            <ul className="space-y-3">
              <li>About us</li>
              <li>Contact us</li>
              <li>Our story</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h5 className="font-semibold">About CREWZIP</h5>
            <ul className="space-y-3">
              <li>Login Swapartner</li>
              <li>Career</li>
            </ul>
          </div>

          <div className="space-y-4">
            <h5 className="font-semibold">Socials</h5>
            <ul className="space-y-3">
              <li>Facebook</li>
              <li>Instagram</li>
              <li>Twitter</li>
              <li>Youtube</li>
              <li>Linkedin</li>
            </ul>
          </div>
        </div> */}

        <div className="pb-5 px-0 md:flex md:items-center md:justify-between">
        <p className="text-xs md:text-sm">© {new Date().getFullYear()} CREWZIP. All rights reserved.</p>
        <div className="mt-4 md:mt-0 md:flex md:items-center text-sm text-gray-400">
            <a href="/privacy" className="hover:text-white transition-colors">
              Privacy Policy
            </a>
            <a href="/terms" className="mx-3 hover:text-white transition-colors">
              Terms and Conditions
            </a>
            <a href="https://www.bradient.com" target="_blank" title="Web Design Los Angeles by BRADIENT" className="text-gray-400 hover:text-white transition-colors">Built by BRADIENT</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
